import React from 'react'
import Helmet from 'react-helmet'
import { navigate } from 'gatsby'
import SEO from '../components/SEO'

const SpanishPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <title>Latam Headless Commerce Week</title>
      </Helmet>

      <SEO
        title="Latam Headless Commerce Week"
        description="5 días conversando sobre las plataformas líderes de la arquitectura API First y las estrellas delHeadless Ecommerce."
      />

      <div className="bg-main bg-black text-white">
        <header className="py-6 bg-black">
          <nav className="container mx-auto flex justify-between">
            <img className="w-18 desktop:w-auto" src="/img/Reign-logo.svg" alt="" />
            <a className="cursor-pointer" onClick={() => navigate('/en')}>
              EN
            </a>
          </nav>
        </header>
        <main>
          <section className="mb-10 desktop:mb-10">
            <div className="container mx-auto text-center">
              <img
                src="/img/Logo-evento.svg"
                alt=""
                className="inline-block mb-5 desktop:mb-10 pt-5 desktop:pt-15 w-72 desktop:w-auto"
              />
              <img src="/img/logo-bajada.svg" alt="" className="inline-block" />
            </div>
          </section>
          {/* DATE SECTION */}
          <section className="mb-5 desktop:mb-20  bg-resume">
            <div className="container mx-auto text-center">
              <p className="px-2 text-base leading-6 desktop:text-4xl desktop:leading-14">
                5 días conversando sobre las <span className="font-weight-bold">plataformas líderes</span> de la
                arquitectura
                <span className="font-weight-bold"> API First</span> y las estrellas del
                <span className="font-weight-bold"> Headless Ecommerce</span>.
              </p>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40 bg-sponsors">
            <div className="container mx-auto text-center">
              <div className="box-special rounded-3xl p-8 desktop:p-20">
                <p className="px-2 text-base leading-6 desktop:text-4xl desktop:leading-14">
                  Revisaremos casos de éxito desarrollados por Reign de soluciones ecommerce para importantes marcas.
                </p>
              </div>
            </div>
          </section>
          {/* SPONSOR SECTION */}
          <section className="mb-15 desktop:mb-40 bg-sponsors">
            <div className="container mx-auto">
              <h3 className="title">
                <span className="font-weight-bold">Nuestros </span>
                Speakers
              </h3>
              <div className="box-special rounded-3xl p-8 desktop:p-20">
                <div className="grid desktop:grid-cols-6 gap-8 desktop:gap-y-16 justify-items-center">
                  <div className="desktop:col-span-2  desktop:col-start-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-contentful.svg" alt="" />
                    <span>Plataforma de contenido API para crear experiencias digitales</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-bigcommerce.svg" alt="" />
                    <span>Ecommerce para una nueva era</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-algolia.svg" alt="" />
                    <span>IA empoderando búsqueda y descubrimiento en sitios y web</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-jamstack.svg" alt="" />
                    <span>El nuevo estándar de arquitectura para la web</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-netlify.svg" alt="" />
                    <span>Las mejores y más rápidas experiencias web en tiempo record</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-commercelayer.svg" alt="" />
                    <span>Comercio headless para marcas globales</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-4Geek.svg" alt="" />
                    <span>El #1 part-time coding bootcamp, 1000+ graduados y soporte de por vida</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-elasticpath.svg" alt="" />
                    <span>Líderes en microservicios para comercio headlesss</span>
                  </div>
                  <div className="desktop:col-span-2 desktop:col-start-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-insider.svg" alt="" />
                    <span>Todo el Growth Management integrado en una plataforma</span>
                  </div>
                  <div className="desktop:col-span-2 text-center text-xs text-gray-400 flex flex-col justify-star">
                    <img src="../img/logo-reblaze.svg" alt="" />
                    <span>Protección web unificada, corriendo de manera nativa sobre tu plataforma cloud preferida</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* PROGRAM SECTION */}
          <section className="mb-15 desktop:mb-40 bg-program">
            <div className="container mx-auto">
              <h3 className="title font-weight-bold">Programa</h3>
              <div className="box-special rounded-3xl p-8 desktop:p-20">
                <div className="grid desktop:grid-cols-2 gap-16 gap-y-8">
                  <div className="col-span-1 text-center desktop:text-left">
                    <span className="text-sm desktop:text-4xl font-weight-bold">26 al 30 de Abril </span>
                    <p className="text-base desktop:text-2xl mt-4 desktop:mt-20">
                      Una semana de charlas sobre Headless. Cada día un tema distinto con speakers de la industria y
                      casos de éxito de Reign.
                    </p>
                  </div>
                  <div className="col-span-1">
                    <ul className="grid justify-center gap-y-2 desktop:gap-y-12 list-none">
                      <li className="flex items-center">
                        <img src="/img/Bandera-Chile.svg" alt="" className="w-6 mr-2 desktop:w-auto desktop:mr-6" />
                        <span className="text-sm desktop:text-3xl">(CL) 13:00 hrs</span>
                      </li>
                      <li className="flex items-center">
                        <img src="/img/Bandera-Colombia.svg" alt="" className="w-6 mr-2 desktop:w-auto desktop:mr-6" />
                        <span className="text-sm desktop:text-3xl">(CO) 12:00 hrs</span>
                      </li>
                      <li className="flex items-center">
                        <img src="/img/Bandera-Mexico.svg" alt="" className="w-6 mr-2 desktop:w-auto desktop:mr-6" />
                        <span className="text-sm desktop:text-3xl">(MX) 12:00 hrs</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40">
            <div className="container mx-auto flex flex-col items-center desktop:items-start">
              <img className="day-image" src="/img/dia-1.svg" alt="" />
              <h4 className="date-subtitle">26 de abril</h4>
              <h3 className="text-lg text-center desktop:text-left leading-5 desktop:text-5xl">
                <span className="font-weight-bold">Entrando a Headless</span>
                <br />
                Tres enfoques desacoplados
              </h3>
              <div className="grid desktop:grid-cols-3">
                <div className="mt-5 desktop:mt-12 text-center desktop:text-left">
                  <span className="text-sm desktop:text-lg">
                    En el primer día conversamos de 3 enfoques diferentes sobre Headless de la mano de JAMstack, MACH Alliance y Elastic Path. Además Contentful nos cuenta de las ventajas de elegir un CMS Headless.
                  </span>
                </div>
              </div>
              <div className="grid desktop:grid-cols-3 mt-24 gap-8">
                <div></div>
                <div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Jamstack.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        JAMstack
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">De una manera muy simple Phil Hawksworth nos explica como JAMStack simplifica el desarrollo de frontends para soluciones headless.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=l6My44IGv1s&t=162s" target="_blank" className="rd-btn-sm">ver video</a>
                    </div>
                  </div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Elasticpath.svg" alt=""  className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Elastic Path
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">Harry Chemko y Shaneil Lafayette nos cuentan más sobre Composable Commerce desde la mirada de Elastic Path.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=l6My44IGv1s&t=3450s" target="_blank" className="rd-btn-sm">ver video</a>
                    </div>
                  </div>
                </div>
                <div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mt-0 desktop:mt-24 mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                          <picture className="flex h-full mx-auto"><img src="../img/logos-cards/MachAlliance.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Mach Alliance - Commercetools
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white leading-6">Kelly Goetsch nos invita a conocer como MACH Alliance ayuda a potenciar las tecnologías de Composable Commerce y soluciones Headless.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=l6My44IGv1s&t=2201s" target="_blank" className="rd-btn-sm">ver video</a>
                      </div>
                    </div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                          <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Contentful.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Contentful
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">Andrew Kumar nos explica la importancia  y los beneficios de Headless a la hora de elegir un CMS.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=l6My44IGv1s&t=5789s" target="_blank" className="rd-btn-sm">ver video</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40">
            <div className="container mx-auto text-right flex flex-col items-center desktop:items-end">
              <img className="day-image" src="/img/dia-2.svg" alt="" />
              <h4 className="date-subtitle">27 de abril</h4>
              <h3 className="text-lg text-center desktop:text-right leading-5 desktop:text-5xl">
                <span className="font-weight-bold">Sumergiéndonos en las soluciones Headless</span>
              </h3>
              <div className="grid desktop:grid-cols-3">
                <div className="col-end-4 mt-5 desktop:mt-12 text-center desktop:text-right">
                  <span className="text-sm desktop:text-lg mt-5 desktop:mt-12">
                    En nuestra segunda jornada revisamos algunos de los beneficios de trabajar en un ecosistema Headless, con las presentaciones de BigCommerce, Netlify y Reblaze.
                  </span>
                </div>
              </div>
              <div className="grid desktop:grid-cols-3 mt-24 gap-8 text-left">
                <div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Bigcommerce.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Big Commerce
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">El CSO de Big Commerce nos comenta sobre como ellos evolucionaron a ser una OPEN SaaS Platform y así ofrecen una solucion Headless al mundo ecommerce.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=0BzWaN1w0VI&t=154s" target="_blank" className="rd-btn-sm">ver video</a>
                    </div>
                  </div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/netlify.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Netlify
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">En su segunda charla, Phil Hawksworth nos cuenta de cómo Netlify impulsa las arquitecturas desacopladas.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=0BzWaN1w0VI&t=2011s" target="_blank" className="rd-btn-sm">ver video</a>
                    </div>
                  </div>
                </div>
                <div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mt-0 desktop:mt-24 mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Reblaze.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Reblaze
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">No todo es desarrollo, sino también seguridad. Por ello invitamos a nuestro partner Reblaze a hablar de su capa de seguridad para arquitecturas en la nube.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=0BzWaN1w0VI&t=4228s" target="_blank" className="rd-btn-sm">ver video</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40 bg-day-3">
            <div className="container mx-auto flex flex-col items-center desktop:items-start">
              <img className="day-image" src="/img/dia-3.svg" alt="" />
              <h4 className="date-subtitle">28 de abril</h4>
              <h3 className="text-lg text-center desktop:text-left leading-5 desktop:text-5xl">
                <span className="font-weight-bold">La muerte del comercio Vieja Escuela</span>
                <br />
                Headless Ecommerce
              </h3>
              <div className="grid desktop:grid-cols-3">
                <div className="mt-5 desktop:mt-12 text-center desktop:text-left">
                  <span className="text-sm desktop:text-lg mt-5 desktop:mt-12">
                    ¿Cómo armamos el ecommerce de Embonor? Te lo contamos de la mano de quienes componen este stack: Algolia, Commerce Layer e Insider.
                  </span>
                </div>
              </div>
              <div className="grid desktop:grid-cols-3 mt-24 gap-8">
                <div></div>
                <div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Embonor.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Embonor
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">El Gerente de Canales Digitales de Embonor nos muestra el portal de Mi Coca-Cola de Embonor y nos comenta algunas anécdotas del proceso de desarrollo.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=ZIiEqqkPai0&t=85s" target="_blank" className="rd-btn-sm">ver video</a>
                    </div>
                  </div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/commercelayer.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Commerce Layer
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">Conoce el aporte de Commerce Layer, al stack de Embonor, como el motor de ecommerce del portal de Mi Coca-Cola.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=ZIiEqqkPai0&t=1551s" target="_blank" className="rd-btn-sm">ver video</a>
                    </div>
                  </div>
                </div>
                <div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mt-0 desktop:mt-24 mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Algolia.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Algolia
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">Descubre con Algolia la importancia de las búsquedas en un ecommerce y cómo esta plataforma aplica IA a sus algoritmos de resultados.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=ZIiEqqkPai0&t=2345s" target="_blank" className="rd-btn-sm">ver video</a>
                      </div>
                    </div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                          <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Insider.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Insider
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">Aprende junto a Bruna Blando cómo Insider ha logrado impulsar las ventas del ecommerce de Embonor.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=ZIiEqqkPai0&t=3701s" target="_blank" className="rd-btn-sm">ver video</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40 bg-day-4">
            <div className="container mx-auto text-right flex flex-col items-center desktop:items-end">
              <img className="day-image" src="/img/dia-4.svg" alt="" />
              <h4 className="date-subtitle">29 de abril</h4>
              <h3 className="text-lg text-center desktop:text-right leading-5 desktop:text-5xl">
                <span className="font-weight-bold">La Autonomía y Gobernanza</span>
                <br />
                de Headless
              </h3>
              <div className="grid desktop:grid-cols-3">
                <div className="col-end-4 mt-5 desktop:mt-12 text-center desktop:text-right">
                  <span className="text-sm desktop:text-lg mt-5 desktop:mt-12">
                    Con el caso de BUPA y con la ayuda de Contentful mostramos la libertad que entrega una estructura Headless para el trabajo de marketing y contenidos. Conoce también los aspectos que se pueden implementar con Big Commerce.
                  </span>
                </div>
              </div>
              <div className="grid desktop:grid-cols-3 mt-24 gap-8 text-left">
                <div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                          <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Contentful.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Contentful
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">Con Andrew Kumar nos preguntamos: ¿Tenemos una plataforma legacy interponiéndose en nuestro futuro?</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=K5NB1o9N0E8&t=121s" target="_blank" className="rd-btn-sm">ver video</a>
                      </div>
                    </div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Bupa.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Bupa
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">El jefe de Experiencia Digital de Bupa nos cuenta sobre el proyecto de "Buparización" que realizamos para los sitios de todas las empresas de Bupa. Proyecto que involucró el buen uso de Contentful y Atomic Design.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=K5NB1o9N0E8&t=961s" target="_blank" className="rd-btn-sm">ver video</a>
                    </div>
                  </div>
                </div>
                <div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mt-0 desktop:mt-24 mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/Bigcommerce.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          BigCommerce
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">El VP de Partnerships de BigCommerce nos comenta cómo funciona su solución para desarrollar plataformas B2B.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=K5NB1o9N0E8&t=2377s" target="_blank" className="rd-btn-sm">ver video</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-15 desktop:mb-40 bg-day-5">
            <div className="container mx-auto flex flex-col items-center desktop:items-start">
              <img className="day-image" src="/img/dia-5.svg" alt="" />
              <h4 className="date-subtitle">30 de abril</h4>
              <h3 className="text-lg text-center desktop:text-left leading-5 desktop:text-5xl">
                <span className="font-weight-bold">Organismos, moléculas y átomos</span>
                <br />
                Nuestro propio Atomic Design
              </h3>
              <div className="grid desktop:grid-cols-3">
                <div className="mt-5 desktop:mt-12 text-center desktop:text-left">
                  <span className="text-sm desktop:text-lg mt-5 desktop:mt-12">
                    El área de UX de Reign nos cuenta sobre los principios de Atomic Design y cómo estos se pueden aplicar específicamente a un proyecto de ecommerce. Friends of Figma nos explica también las aplicaciones de Atomic en Figma.
                  </span>
                </div>
              </div>
              <div className="grid desktop:grid-cols-3 mt-24 gap-8">
                <div></div>
                <div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mb-8">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5">
                        <picture className="flex h-full"><img src="../img/logos-cards/Reign-UX.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Reign
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">La Líder de UX de Reign nos cuenta por qué hemos adoptado Atomic Design como eje principal en todos nuestros desarrollos, mostrando una serie de interesantes ejemplos.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=d6evAyfB6UI&t=111s" target="_blank" className="rd-btn-sm">ver video</a>
                    </div>
                  </div>
                  <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special">
                    <div className="relative">
                      <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card px-5 text-center">
                        <picture className="flex h-full"><img src="../img/logos-cards/Reign-UI.svg" alt="" className="mx-auto"/></picture>
                      </div>
                    </div>
                    <div className="p-11">
                      <span className="mr-10 text-3xl text-sm truncate-2nd">
                        Reign
                      </span>
                      <div className="flex items-start justify-between my-5">
                        <span className="text-lg text-white  leading-6">Dos de nuestras Rockstars del UI nos muestran un ejemplo de Atomic Design aplicado al ecommerce.</span>
                      </div>
                      <a href="https://www.youtube.com/watch?v=d6evAyfB6UI&t=1116s" target="_blank" className="rd-btn-sm">ver video</a>
                    </div>
                  </div>
                </div>
                <div>
                    <div className="w-full max-w-sm overflow-hidden rounded-2xl box-special mt-0 desktop:mt-24 mb-8">
                      <div className="relative">
                        <div className="h-48 bg-cover bg-no-repeat bg-center gradient-card flex px-5">
                        <picture className="flex h-full mx-auto"><img src="../img/logos-cards/FriendsOfFigma.svg" alt="" className="mx-auto"/></picture>
                        </div>
                      </div>
                      <div className="p-11">
                        <span className="mr-10 text-3xl text-sm truncate-2nd">
                          Friends of Figma
                        </span>
                        <div className="flex items-start justify-between my-5">
                          <span className="text-lg text-white  leading-6">Para aprender bien de Figma y Atomic Design qué mejor que un playground de la comunidad Friends of Figma, con los beneficios de usar esta herramienta para crear diseño Atómico.</span>
                        </div>
                        <a href="https://www.youtube.com/watch?v=d6evAyfB6UI&t=2652s" target="_blank" className="rd-btn-sm">ver video</a>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <footer className="bg-black">
          <div className="container mx-auto py-10">
            <div className="grid desktop:grid-cols-4 gap-4 text-lg desktop:text-2xl text-center desktop:text-left">
              <div className="text-center">
                <img src="/img/ico-mapamundi.svg" alt="" className="inline desktop:block mb-5 desktop:mb-0" />
              </div>
              <div>
                <h4 className="font-bold mb-3">Latin America</h4>
                <h5 className="mb-5">hola@reign.cl</h5>
                <h4 className="font-bold mb-3">North America</h4>
                <h5 className="mb-5">hello@reign.cl</h5>
                <span className="text-xs desktop:text-lg">
                  Los Conquistadores 1730
                  <br />
                  Of. 2301 Santiago, Chile
                </span>
              </div>
              <div />
              <div>
                <h4 className="mb-5 font-bold">Follow us</h4>
                <div className="flex justify-center desktop:justify-start">
                  <a href="https://www.linkedin.com/company/reigncl" target="_blank" className="mr-5">
                    <img src="../img/linkedin.svg" alt="" />
                  </a>
                  <a href="https://medium.com/reigncl" target="_blank">
                    <img src="../img/medium.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default SpanishPage
